import styled from 'styled-components';
import { useEffect, useState } from 'react';
import nbpApi from './api/nbp';
import Tag from './Tag';
import StravaActivities from './StravaActivities';

const Root = styled.div`
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 48px;
  }

  div {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 48px;
    margin-left: 10px;
  }
`;

const Tags = styled.div`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
`;

const Form = styled.form`
  margin: 20px 0;

  select {
    display: block;
  }

  input {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    background-color: #0d6efd;
    color: #ffffff;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1.5em;
  }

  input:disabled {
    background-color: lightgray;
    color: #000000;
  }
`;

const App = () => {
  const [rates, setRates] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const { data } = await nbpApi.get('tables/a');
        setRates(data[0].rates);
      } catch {
        setRates([]);
      }
    };

    fetchRates();
  }, []);

  const [ratesForCurrencies, currenciesToSelect] =
    rates.reduce(([t, f], rate) => currencies.includes(rate.code) ? [[...t, rate], f] : [t, [...f, rate]], [[], []]);

  const handleDeleteCurrency = (code) => {
    setCurrencies(currencies.filter((curr) => code !== curr));
  };

  const handleSelectedCurrencies = (event) => {
    setSelectedCurrencies([...event.target.selectedOptions].map(({ value }) => value));
  };

  const addCurrencies = (event) => {
    event.preventDefault();
    setCurrencies([...currencies, ...selectedCurrencies]);
    setSelectedCurrencies([]);
  };

  return (
    <Root>
      <Header>
        <img src="logo192.png" alt="LambdaMonada" />
        <div>Lambda Monada</div>
      </Header>
      <h2>
        Bieżące kursy walut:
      </h2>
      {
        currencies.length ? 
          <>
            <ul>
              {ratesForCurrencies.map(({ currency, code, mid }) => <li key={code}>{currency}: {mid}</li>)}
            </ul>
            <Tags>
              {ratesForCurrencies.map(({ code }) => <Tag key={code} currencyCode={code} onDelete={handleDeleteCurrency} />)}
            </Tags>
          </> :
          <Section>
            Nie wybrałeś jeszcze żadnej waluty
          </Section>
      }
      {
        !!currenciesToSelect.length &&
          <Section>
            <Form onSubmit={addCurrencies}>
              <select name="currencies" value={selectedCurrencies} onChange={handleSelectedCurrencies} multiple size={Math.min(currenciesToSelect.length, 10)}>
                {currenciesToSelect.map(({ currency, code }) => <option key={code} value={code}>{currency}</option>)}
              </select>
              <input type="submit" value="Dodaj waluty" disabled={!selectedCurrencies.length}/>
            </Form>
          </Section>
      }
      <hr/>
      <StravaActivities/>
    </Root>
  );
};

export default App;

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const StyledTag = styled.span`
  background-color: #0d6efd;
  color: #ffffff;
  padding: 5px 10px;
  margin: 10px;
  border-radius: 10px;

  svg {
    padding-left: 5px;
  }
`;

const Tag = ({ currencyCode, onDelete }) => {
  const handleDeletion = () => {
    onDelete(currencyCode);
  };

  return (
    <StyledTag>
      {currencyCode}
      <FontAwesomeIcon icon={faTrashCan} onClick={handleDeletion} />
    </StyledTag>
  );
}

export default Tag;

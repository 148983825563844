import { useEffect } from 'react';
import styled from 'styled-components';
import StravaActivity from './StravaActivity';

const Activities = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const StravaActivities = () => {
    useEffect(() => {
      const body = document.querySelector('body');
      const script = document.createElement('script');
      script.src = 'https://strava-embeds.com/embed.js';
      body.appendChild(script);

      return () => {
        body.removeChild(script);
      };
    }, []);

    const activities = [11180017367, 11181655463, 11491360643, 11501377615, 11530690827, 
      11538386229, 11656396715, 11765225942, 11819290546, 11933376775];

    return (
      <Activities>
        {activities.map((activityId) => <StravaActivity key={`activity-${activityId}`} activityId={activityId}/>)}
      </Activities>
      );
};

export default StravaActivities;

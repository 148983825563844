const StravaActivity = ({ activityId }) => (
  <div
    className="strava-embed-placeholder"
    data-embed-type="activity"
    data-embed-id={activityId}
    data-style="standard">
  </div>
);

export default StravaActivity;
